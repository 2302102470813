<template>
    <div>
        <h2>Credenciais</h2>
        
        <!---
        
          <Message :msg="msg" v-show="msg"/>

        
        -->
        
                
        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

        <div class="painel-superior">
            <button class="novo-btn" @click="salvarToken">Salvar</button>
            <button class="novo-btn" @click="cancelarToken">Cancelar</button> 

        </div>
      
        <form id="credenciais-form" >
            

            <div class="input-container">
                <label for="publictoken">Public Token</label>
                <input type="text" id="publictoken" name="publictoken"  v-model="publictoken" placeholder="Public Token">
            </div>

            <div class="input-container">
                <label for="devicetoken">Device Token</label>
                <input type="text" id="devicetoken" name="devicetoken"  v-model="devicetoken" placeholder="Device Token">
            </div>



        </form>
        
        
    </div>
</template>

<script>
//import Message from '../../components/Message.vue'
import {urlAPI} from '../../config.js'

export default {
   
   name:'CredenciaisForm', 
   components:{
       //Message,
      
   },
   
   
   data(){
       return{
           publictoken:this.$store.getters.getPublicToken,
           devicetoken:this.$store.getters.getDeviceToken,
       }
   },
   methods:{

       cancelarToken(){
            console.log('não salvar token')
       },
    
        async salvarToken(){
           
            const data = {
              publictoken: this.publictoken,   
              devicetoken:  this.devicetoken,
              msg: this.msg

           }

           let dataJson = JSON.stringify(data)
           console.log(dataJson)
           
           console.log(urlAPI) 
          
           try {
                
               console.log('Dados que serão salvos:', this.$store.getters.getUserID, data.publictoken, data.devicetoken)
            
                const req = await fetch(urlAPI+`/users/updatetokenwht/idusuario/${this.$store.getters.getUserID}/publictoken/${data.publictoken}/devicetoken/${data.devicetoken}/token/${this.$store.getters.getTokenAdmin}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                    //body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('--->token',this.$store.getters.getTokenAdmin)
                console.log('--->Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (dataJson.indexOf("OK") > 0){

                   this.$store.commit('registrePublicToken', data.publictoken)
                   this.$store.commit('registreDeviceToken', data.devicetoken)                   
                   this.tipoAlerta = "alert-success"
                   this.msg = "salvo com sucesso"

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = "Falha ao enviar dados"


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 
                console.log('Error: ',error);
           }   
            
           
          setTimeout(()=>this.msg = "", 3000) 

           this.publictoken = ''
           this.devicetoken = ''

           this.publictoken = this.$store.getters.getPublicToken
           this.devicetoken = this.$store.getters.getDeviceToken


       }

   },
   mounted(){
      
   }   
}
</script>

<style scoped>
     #credenciais-form{
         max-width: 400px;
         margin: 0 auto;
     }

     .input-container{
         display: flex;
         flex-direction: column;
         margin-bottom: 20px;
     }

     label{
         font-weight: bold;
         margin-bottom: 15px;
         color: #FCBA03;
         padding: 5px 10px;
        
     }

     input, select{
         padding: 5px 10px;
         width: 300px;
     }

     .novo-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px auto;
      cursor: pointer;
      transition: .5s;
      min-width: 90px;
      
   }

   .novo-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .painel-superior{
      
      float:right;
      
           
  } 





</style>