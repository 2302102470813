<template>

<div class="main-container">
    
  
    <CredenciaisForm v-if="isAuth"/>
    <Login  v-else/>

   
  </div>

    
</template>

<script>
//import CredenciaisForm from "../components/forms/WhatsApp/CredenciaisForm.vue" 
import CredenciaisForm from "../components/forms/CredenciaisForm.vue" 

import Login from "./Login.vue"

export default {
    name:"Credenciais",
    components:{
        CredenciaisForm,
        Login
    },
    
    computed:{
      
      isAuth(){
         if (this.$store.getters.getUserID != null){
           return true
         } else {
           return false
         }

       }

    }
}
</script>

<style scoped>
  .main-container{
    margin: 50px;
    min-height: 250px;
  }
</style>

